import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import router from "./plugins/router";
import "./assets/styles/site.scss";
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";

Vue.use(VueGtag, {
    config: { id: "G-RQPKG236HK" },
});

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
