<template>
    <div class="container-lg py-3" id="accueil">
         <div id="confirm_register" v-if="isFromY" class="d-flex-center flex-column px-2 user-select-none">
            <div class="alert alert-success text-white container">
                <i class="fa-solid fa-check me-2"></i>
                <span>Merci pour votre demande</span>
            </div>

            <div class="alert alert-warning container" role="alert">
                <div>
                    <i class="fa-solid fa-triangle-exclamation me-2"></i> <b>Attention</b>, pour des raisons de sécurité tenant à la nature du Théâtre, une
                    jauge détermine le nombre maximum de personnes présentes.
                </div>
                <div class="mt-3 fw-bold">
                    Votre inscription personnelle <u>sera donc définitivement validée</u> à réception du mail de confirmation qui vous parviendra en amont de
                    l'évènement et qui vous sera demandé à l'accueil.
                </div>
            </div>

            <button @click="jaicompris" class="btn btn-blueto text-white btn-lg mt-3" type="button">J'ai compris !</button>
        </div>

        <div v-if="isFromN" class="alert alert-success text-white container">
            <i class="fa-solid fa-check me-2"></i>
            <span>Votre réponse a bien été prise en compte. Merci.</span>
        </div>

        <AccueilView ref="home" id="home" />
        <ProgrammeView ref="programme" id="programme" />
        <AccesView ref="acces" id="acces" />
    </div>
</template>

<script>
import AccueilView from "./_AccueilView.vue";
import ProgrammeView from "./_ProgrammeView.vue";
import AccesView from "./_AccesView.vue";

export default {
    components: {
        AccueilView,
        ProgrammeView,
        AccesView,
    },
    data() {
        let _isFromY = localStorage.fromy && JSON.parse(localStorage.fromy);
        localStorage.fromy = false;

        if (_isFromY) document.documentElement.style.overflow = "hidden";

        let _isFromN = localStorage.fromn && JSON.parse(localStorage.fromn);
        localStorage.fromn = false;

        return {
            isFromY: _isFromY,
            isFromN: _isFromN,
        };
    },
    methods: {
        jaicompris: function () {
            document.documentElement.style.overflow = "auto";
            this.isFromY = false;
            this.isFromN = false;
        },
    },
};
</script>
