<template>
    <div class="acces">
        <div class="infos-content text-white position-relative">
            <h2 class="title-section">Infos & Accès</h2>
            <div class="container py-5">
                <div class="d-flex justify-content-lg-around flex-wrap mt-2">
                    <div class="m-4">
                        <h4 class="d-flex align-items-center text-600 pb-1">
                            <i class="fa-solid fs-2 fa-location-dot text-secondary me-3"></i>
                            <span>Adresse</span>
                        </h4>
                        <div class="ms-5 mt-3">
                            <b>Théâtre l'Île Ô</b>
                            <br />
                            Berge Bertha Von Suttner - 69007 Lyon
                            <br />
                            <i class="text-secondary fw-medium">L'Île Ô se situe sur la rive gauche du Rhône</i>
                            <br />
                            au pied du Pont Gallieni, en face du n°3 Avenue Leclerc
                            <br />
                            
                        </div>
                    </div>
                    <div class="m-4">
                        <h4 class="d-flex align-items-center text-600">
                            <i class="fa-solid fs-2 fa-address-book text-secondary me-2"></i>
                            <span>Pour toute question, vous pouvez nous contacter</span>
                        </h4>

                        <div class="d-flex flex-column ms-5">
                            <div class="my-2--5">
                                <i class="fa-solid fa-phone text-secondary me-2--5"></i>
                                <a href="tel:0478770723">04 78 77 07 23</a>
                            </div>
                            <div>
                                <i class="fa-solid fa-at text-secondary me-2--5"></i>
                                <a href="mailto:bzghali@chimie-aura.org">bzghali@chimie-aura.org</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gmap mb-3">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11136.708517630233!2d4.8328521!3d45.7475948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4ebaa586951a9%3A0x90babbf90f71d034!2zVGjDqcOidHJlIEzigJnDrmxlIMOU!5e0!3m2!1sfr!2sfr!4v1697200846032!5m2!1sfr!2sfr"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>

        <div class="row g-3">
            <div class="col-12 col-md">
                <div class="card border-blueto">
                    <div class="card-body">
                        <h5 class="card-title text-primary">
                            <i class="fa-solid fa-train-tram text-primary me-3"></i>
                            <span>Transports en commun</span>
                        </h5>

                        <p class="card-text d-flex flex-column mt-3">
                            <span>
                                L'Île Ô est facilement accessible via les transports en commun lyonnais.
                            </span>
                            <span class="ms-4 my-2">
                                <i class="fa-solid fa-circle fa-2xs text-primary me-2"></i>
                                <span>Metro A, arrêt <b>Perrache</b> (+10min à pied)</span>
                            </span>
                            <span class="ms-4 my-2">
                                <i class="fa-solid fa-circle fa-2xs text-primary me-2"></i>
                                <span>TRAM T1, arrêt <b>Quai Claude Bernard</b></span>
                            </span>
                            <span class="ms-4 my-2">
                                <i class="fa-solid fa-circle fa-2xs text-primary me-2"></i>
                                <span>TRAM T2, arrêt <b>Centre Berthelot</b></span>
                            </span>
                            <span class="ms-4 my-2">
                                <i class="fa-solid fa-circle fa-2xs text-primary me-2"></i>
                                <span>Bus 34 et 60, arrêt <b>Pont Gallieni RG</b></span>
                            </span>

                            <span class="mt-3">
                                <i class="fa-solid fa-person-biking me-2 text-primary"></i>
                                <span><b class="text-primary">Station Vélo'V</b> à 4 minutes à pied. Parc à vélos sur les berges.</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card border-secondary">
                    <div class="card-body">
                        <h5 class="card-title text-secondary">
                            <i class="fa-solid fa-car text-secondary me-3"></i>
                            <span>Parking LPA</span>
                        </h5>

                        <p class="card-text d-flex flex-column align-items-center mt-3">
                            <span class="mt-2 mb-1">99 Rue de Marseille </span>
                            <span>69007 Lyon </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>
