<template>
    <div class="container-fluid inscription mx-auto d-flex-center flex-column my-4">
        <div v-if="isLoading" class="loader d-flex-center flex-column">
            <i class="fa-solid fa-flask fa-shake text-primary fa-5x"></i>
            <span class="text-white mt-4 text-uppercase">Envoi du formulaire en cours...</span>
        </div>

        <div v-if="hasError" class="w-100 alert alert-danger text-white d-flex align-items-center rounded-1" role="alert">
            <i class="fa-solid fa-times me-3"></i>
            <div>Une erreur est survenue lors de la validation de votre inscription, veuillez réessayer ultérieurement.</div>
        </div>

        <div class="bg-white p-3 shadow bg-opacity-50 rounded mw-100" v-if="!isClosed">
            <h2 class="title-section w-100 bg-primary rounded mb-3">Formulaire de pré-inscription</h2>
            <div class="my-3 text-center"><img src="../assets/img/logo-150.png" style="max-width: 150px" alt="" /></div>
            <div class="alert alert-warning" role="alert">
                <div>
                    <i class="fa-solid fa-triangle-exclamation me-2"></i> Attention, pour des raisons de sécurité tenant à la nature du Théâtre, une
                    jauge détermine le <b>nombre maximum</b> de personnes présentes.
                </div>
                <div class="mt-3">
                    Votre inscription personnelle sera donc <b>définitivement validée à réception du mail</b> de confirmation qui vous parviendra en
                    amont de l'événement et qui vous sera demandé à l'accueil
                </div>
            </div>

            <form method="POST" class="needs-validation" novalidate>
                <div class="row g-3">
                    <!-- Nom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.last_name"
                                id="last_name"
                                name="last_name"
                                placeholder="Dupond"
                                required
                            />
                            <label for="last_name">Nom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                        </div>
                    </div>

                    <!-- Prenom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.first_name"
                                id="first_name"
                                name="first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="first_name">Prénom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="col-12 col-sm-8">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="email"
                                v-model="form_data.email"
                                id="email_address"
                                name="email_address"
                                placeholder="nom@exemple.fr"
                                required
                            />
                            <label for="email_address">Email*</label>
                            <div class="invalid-feedback">Email invalide</div>
                        </div>
                    </div>

                    <!-- Numero de telephone -->
                    <div class="col-12 col-sm-4">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="tel"
                                v-model="form_data.phone_number"
                                id="phone_number"
                                name="phone_number"
                                placeholder="0701020304"
                                :required="form_data.need_hotel"
                            />
                            <label for="phone_number">Numéro de téléphone</label>
                            <div class="invalid-feedback">Veuillez renseigner email invalide</div>
                        </div>
                    </div>

                    <!-- Fonctions -->
                    <div class="col-12">
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                v-model="form_data.company_role"
                                id="company_role"
                                name="company_role"
                                placeholder="Directeur commercial"
                                required
                            />
                            <label for="company_role">Fonction*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre fonction</div>
                        </div>
                    </div>

                    <!-- Entité -->
                    <div class="col-12">
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                v-model="form_data.company_name"
                                id="company_name"
                                name="company_name"
                                placeholder="SAS ENTREPRISE"
                                required
                            />
                            <label for="company_name">Entité*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre entité</div>
                        </div>
                    </div>

                    <div class="col-12 d-flex-start py-1 py-sm-3">
                        <span class="fs-5">Je participerai aux 150 ans :</span>
                        <button
                            type="button"
                            class="btn btn-lg mx-3"
                            v-bind:class="[form_data.is_participating ? 'btn-primary' : 'btn-outline-primary']"
                            @click="participate(true)"
                            for="chk_yes"
                        >
                            Oui
                        </button>
                        <button
                            type="button"
                            class="btn btn-lg"
                            v-bind:class="[
                                form_data.is_participating == null || form_data.is_participating ? 'btn-outline-secondary' : 'btn-secondary',
                            ]"
                            @click="participate(false)"
                            for="chk_no"
                        >
                            Non
                        </button>
                        <input
                            type="text"
                            class="form-control d-none"
                            v-model="form_data.is_participating"
                            id="is_participating"
                            name="is_participating"
                            required
                        />
                        <div class="invalid-feedback">Veuillez indiquer si vous participez aux 150 ans</div>
                    </div>
                </div>

                <button @click="register" class="w-100 btn btn-blueto text-white btn-lg mt-3" type="button">Valider</button>
                <div class="m-2 small">* champs obligatoires</div>
            </form>
        </div>

        <div class="bg-white text-center p-3 shadow bg-opacity-50 rounded" v-if="isClosed">
            <h4 class="mb-4 text-secondary" style="line-height: 2rem">
                Nous sommes désolés, du fait de son succès, nous sommes dans l'obligation de clôturer les inscriptions à cet événement.
            </h4>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const saveChimieAuraRegistration = httpsCallable(functions, "saveChimieAuraRegistration");

const empty_form = {
    last_name: "",
    first_name: "",
    email: "",
    phone_number: "",
    company_role: "",
    company_name: "",
    is_participating: null,
};

export default {
    created() {
        this.form_data = Object.assign({}, empty_form);
    },
    data() {
        return {
            isClosed: false,
            isLoading: false,
            hasError: false,
            form_data: {
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                phone_number: "0601020304",
                company_name: "DMP EVENTS",
                company_role: "Infirmier",
                is_participating: true,
            },
        };
    },
    props: {},
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
        },
        async register() {
            if (!this.isLoading) {
                this.isLoading = true;

                const form = document.querySelector(".needs-validation");

                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    await saveChimieAuraRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                router.push({ name: this.form_data.is_participating ? "done-y" : "done-n" });
                                this.cleanData();
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
.inscription {
    width: 100%;
    max-width: 768px;
}

$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
