/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegistrationView from "../views/RegistrationView.vue";
import NotFoundView from "../views/NotFoundView.vue";

import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const extractRegistrations = httpsCallable(functions, "extractRegistrations");

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/inscription",
        name: "inscription",
        component: RegistrationView,
    },
    {
        path: "/done-y",
        name: "done-y",
        redirect: (to) => {
            localStorage.fromy = true;
            return { name: "home" };
        },
    },
    {
        path: "/done-n",
        name: "done-n",
        redirect: (to) => {
            localStorage.fromn = true;
            return { name: "home" };
        },
    },
    {
        path: "/404-not-found",
        name: "404",
        component: NotFoundView,
    },
    {
        path: "*",
        redirect: { name: "404" },
    },
];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const navbarHeight = document.getElementById("mynavbar").offsetHeight;
                resolve(
                    to.hash
                        ? {
                              selector: to.hash,
                              offset: { x: 0, y: navbarHeight },
                              behavior: "smooth",
                          }
                        : { x: 0, y: 0 }
                );
            }, 250);
        });
    },
});

export default router;
