<template>
    <div class="home">
        <div class="home__image d-flex justify-content-between flex-column">
            <div class="home__image__copyright">
                <i class="fa-regular fa-copyright me-1"></i>
                <span>BOW architecture navale</span>
            </div>
            <h1 class="home__title my-5 d-flex flex-column align-items-start">
                <span class="home__title__year d-flex flex-column">
                    <span>1873 - 2023</span>
                    <span class="home__title__underline mb-4"></span>
                </span>
                <span class="home__title__text my-3 text-center d-flex flex-column">
                    <span>SOIRÉE DES <span class="text-nowrap">150 ANS</span></span>
                    <span>FRANCE CHIMIE AURA</span>
                    <span class="home__title__text__soiree"> UNE SOIRÉE DURABLE ET MÉMORABLE</span>
                </span>
            </h1>
            <div class="cab mb-3 me-4">
                <MyCountdown class="d-none d-md-block" :date="countdown_end" />
            </div>
        </div>

        <div class="home__description container d-flex-center flex-column py-5">
            <MyCountdown class="d-sm-block d-md-none phonemode align-self-stretch" :date="countdown_end"></MyCountdown>

            <div class="d-flex-center flex-column my-4 text-center px-5--5 py-4 shadow-lg rounded">
                <h1 class="text-primary fw-bold">Soirée des 150 ans</h1>
                <h2 class="mb-2 fw-medium text-decoration-none text-gray-800">1873 - 2023</h2>
                <span class="my-3"><i class="fa-solid fa-calendar-check text-secondary me-2--5"></i>Lundi 27 novembre 2023</span>
                <span class="mb-3"> <i class="fa-regular fa-clock fa-sm text-secondary me-2"></i>18h30 </span>
                <span class="fw-medium"><i class="fa-solid fa-location-dot text-secondary me-2--5"></i>Théâtre L'Ile Ô</span>
                <span>Berge Bertha Von Suttner</span>
                <span>69007 Lyon</span>
            </div>

            <router-link
                :to="{ name: 'inscription' }"
                class="btn btn-lg btn-bicolor fw-bold my-4 d-flex-center"
                style="width: 210px; height: 65px; text-transform: uppercase"
            >
                PRÉ-INSCRIPTION
            </router-link>

            <div class="text-container">
                <div class="separation"></div>

                <div class="mt-5 d-flex flex-column align-items-center">
                    <img src="../assets/img/logo-150.png" style="max-width: 200px" alt="" />
                    <h2 class="fw-bold text-secondary my-4">1873 - 2023...</h2>
                    <span class="mb-4">
                        150 raisons de célébrer l'histoire passée, présente et future de notre Organisation Professionnelle régionale au service de la
                        Chimie.
                    </span>

                    <span>
                        Rejoignez-nous pour une soirée
                        <b class="text-primary">durable et mémorable</b>, en l'honneur de notre <b class="text-primary">150e anniversaire</b> !
                    </span>
                </div>

                <div class="separation"></div>
            </div>
        </div>
    </div>
</template>

<script>
import MyCountdown from "@/components/MyCountdown.vue";

export default {
    components: {
        MyCountdown,
    },
    data() {
        return {
            countdown_end: new Date("2023-11-27T19:00:00"),
        };
    },
};
</script>
